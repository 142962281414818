/* This code executes outside of the Angular application
   in order to detect incompatible, older browsers.
   This code overwrites the default message with a custom 
   message if available and appends a class to use
   if the browser supports animations.
*/
(() => {
  const hostElement = document.getElementById('incompatible');
  let customMessage = "You're using a browser that is not supported.";
  const messageElement = document.createElement('div');

  // Check for customized message
  if (
    !!CONFIG &&
    CONFIG.APP &&
    CONFIG.APP.global &&
    CONFIG.APP.global.browser_compatibility &&
    CONFIG.APP.global.browser_compatibility.message
  ) {
    customMessage = CONFIG.APP.global.browser_compatibility.message;
  }

  messageElement.classList = 'hideIfAnimationsWork';
  messageElement.innerHTML = customMessage;
  hostElement.innerHTML = '';
  hostElement.appendChild(messageElement);
})();
